import React from "react";
import "./app.loader.css";
const Loader = () => {
  return (
    <div className="app__loader_modal">
      <div className="app__loader" />
    </div>
  );
};

export default Loader;
