import { createAsyncThunk } from "@reduxjs/toolkit";
import publicAPIServices from "../../services/APIServices";
const API = "/calculator/";

export const getResults = createAsyncThunk(
  "calculator/getResults",
  async (payload) => {
    try {
      const response = await publicAPIServices.postData(
        API + "generate-results",
        payload
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);
