import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import servicesReducers from "./servicesSlices/servicesReducers";
import calculatorReducers from "./calculatorSlice/calculatorReducers";
import quizReducers from "./quizSlice/quizReducers";
const reducer = combineReducers({
  calculator: calculatorReducers,
  quiz: quizReducers,
});

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});
