import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/styles/app.calculator.page.css";
import "./assets/styles/app.global.css";
import "./assets/styles/app.layout.css";

import { BrowserRouter } from "react-router-dom";
import { store } from "./context/store";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter basename="/">
    <HelmetProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </HelmetProvider>
  </BrowserRouter>
);
