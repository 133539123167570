import { useState } from "react";
import "./contact.form.css";
import { IoIosCloseCircle } from "react-icons/io";
import publicAPIServices from "../../services/APIServices";

const ContactForm = ({ closeContactModal }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSucces] = useState(null);

  const msg_url = "/contact/send-message";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSucces(null);

    const data = {
      name,
      email,
      message,
    };

    try {
      const sendMSG = await publicAPIServices.postData(msg_url, data);
      console.log(sendMSG);
      if (sendMSG.status === 200) {
        setSucces(
          " Köszönjük az üzeneted! Hamarosan felvesszük Veled a kapcsolatot."
        );
      } else {
        setError("Hiba történt. Kérlek, próbáld meg pár újra pár perc múlva.");
      }
    } catch (err) {
      setError("Hiba történt. Kérlek, próbáld meg pár újra pár perc múlva.");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    closeContactModal();
  };

  return (
    <div className="contact_modal">
      <form className="contact_form" onSubmit={handleSubmit}>
        <div className="contact_form_close_btn">
          <IoIosCloseCircle
            style={{ cursor: "pointer" }}
            onClick={handleClose}
          />
        </div>
        <h2>Kapcsolatfelvételi űrlap</h2>
        <input
          required
          placeholder="Név (kötelező)"
          type="text"
          autoComplete="name"
          className="contact_form-input"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          required
          placeholder="E-mail cím (kötelező)"
          className="contact_form-input"
          type="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <textarea
          required
          placeholder="Miben segíthetünk? (kötelező)"
          className="contact_form-textarea"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        {success !== null ? (
          <div className="success_box">
            {success}{" "}
            <button onClick={handleClose}>
              Bezárom a kapcsolatfelvételi ablakot
            </button>
          </div>
        ) : (
          <>
            <button
              type="submit"
              className="contact_form_submit_btn"
              disabled={loading}
            >
              {loading ? "Folyamatban..." : "Elküldöm"}
            </button>
            {error && <div className="error_box">{error}</div>}
          </>
        )}
      </form>
    </div>
  );
};

export default ContactForm;
