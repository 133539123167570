// quizSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  questions: [],
  gender: null,
  height: null,
  weight: null,
  activity: null,
  age: null,
  targetWeight: null,
};

const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    resetQuizState: (state) => {
      state.questions = [];
      state.gender = null;
      state.height = null;
      state.weight = null;
      state.activity = null;
      state.age = null;
      state.targetWeight = null;
    },
    addAnswer: (state, action) => {
      const { _id, answer } = action.payload;
      const question = state.questions.find((q) => q._id === _id);
      if (question) {
        question.answer = answer;
      } else {
        state.questions.push(action.payload);
      }
    },
    addGender: (state, action) => {
      state.gender = action.payload;
    },
    addWeight: (state, action) => {
      state.weight = action.payload;
    },
    addHeight: (state, action) => {
      state.height = action.payload;
    },
    addActivity: (state, action) => {
      state.activity = action.payload;
    },
    addAge: (state, action) => {
      state.age = action.payload;
    },
    addTargetWeight: (state, action) => {
      state.targetWeight = action.payload;
    },
  },
});

export const {
  resetQuizState,
  addAnswer,
  addGender,
  addWeight,
  addHeight,
  addActivity,
  addAge,
  addTargetWeight,
} = quizSlice.actions;

export default quizSlice.reducer;
