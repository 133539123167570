// calculatorSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { getResults } from "./calculatorActions";

const initialState = {
  calculator_loading: false,
  calculator_success: false,
  calculator_error: false,
  calculator_data: null,
};

const calculatorSlice = createSlice({
  name: "calculator",
  initialState,
  reducers: {
    resetCalculatorState: (state) => {
      state.calculator_loading = false;
      state.calculator_success = false;
      state.calculator_error = false;
      state.calculator_data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResults.pending, (state) => {
        state.calculator_loading = true;
        state.calculator_error = false;
        state.calculator_success = false;
      })
      .addCase(getResults.fulfilled, (state, action) => {
        state.calculator_loading = false;
        state.calculator_error = false;
        state.calculator_success = true;
        state.calculator_data = action.payload;
      })
      .addCase(getResults.rejected, (state, action) => {
        state.calculator_loading = false;
        state.calculator_error = true;
      });
  },
});

export const { resetCalculatorState } = calculatorSlice.actions;

export default calculatorSlice.reducer;
