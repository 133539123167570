// libraries
import { Routes, Route, useLocation } from "react-router-dom";
import { lazy, Suspense } from "react";
import CookieConsent from "react-cookie-consent";
// import Header from "./layout/header/Header";
import Footer from "./layout/footer/Footer";
import Loader from "./components/Loader/Loader";

const __test = lazy(() => import("./pages/__test"));
const CalculatorPage = lazy(() =>
  import("./pages/CalculatorPage/CalculatorPage")
);
const MarketingPage = lazy(() => import("./pages/MarketingPage/MarketingPage"));
const SikertelenPage = lazy(() =>
  import("./pages/SikertelenPage/SikertelenPage")
);
const ResultPage = lazy(() => import("./pages/ResultPage/ResultPage"));
const CheckoutPage = lazy(() => import("./pages/CheckoutPage/CheckoutPage"));
const ThankYouPage = lazy(() => import("./pages/ThankYouPage/ThankYouPage"));
const OrderPage = lazy(() => import("./pages/OrderPage/OrderPage"));
const PageNotFound = lazy(() => import("./pages/PageNotFound/PageNotFound"));
//legals
const TermsPage = lazy(() => import("./pages/LegalPages/TermsPage"));
const AdatvedelemPage = lazy(() =>
  import("./pages/LegalPages/AdatvedelemPage")
);

// temp
// import EmailDesignerTemplate from "./pages/EmailDesignerTemplate/EmailDesignerTemplate";

const App = () => {
  const { pathname } = useLocation();
  const app_url = pathname.split("/")[1];

  return (
    <div className={app_url === "start" ? "tr-app app-w-bg" : "tr-app"}>
      <div className={app_url === "start" ? "quiz-content" : "content"}>
        <Suspense
          fallback={
            <>
              <Loader />
            </>
          }
        >
          <CookieConsent
            location="bottom"
            buttonText="Elfogadom"
            ariaAcceptLabel="Elfogadom"
            cookieName="cc_v1"
            style={{
              background: "#37B221",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            buttonStyle={{
              color: "#fff",
              backgroundColor: "#333",
              fontSize: "16px",
              fontWeight: "700",
              borderRadius: "18px",
              padding: "10px 10px",
            }}
            contentStyle={{
              maxWidth: "980px",
              width: "100%",
            }}
            expires={150}
          >
            {/* <div style={{ maxWidth: "980px", margin: "auto" }}> */}
            <span style={{ color: "#fff" }}>
              Az oldalon cookie-kat használunk, hogy a legjobb felhasználói
              élményt nyújthassuk.
            </span>
            <span>
              <a
                style={{
                  color: "#ffffff",
                  fontWeight: "700",
                  marginLeft: "25px",
                }}
                href={"/adatvedelem"}
                target="_blank"
              >
                További infók
              </a>
            </span>
            {/* </div> */}
          </CookieConsent>
          <Routes>
            <Route path="/adatvedelem" element={<AdatvedelemPage />} />
            <Route path="/aszf" element={<TermsPage />} />
            <Route path="/csomagom" element={<OrderPage />} />
            <Route path="/koszonjuk" element={<ThankYouPage />} />
            {/* <Route path="/test" element={<__test />} /> */}
            <Route path="/penztar" element={<CheckoutPage />} />
            <Route path="/eredmeny/sikertelen" element={<SikertelenPage />} />
            <Route path="/eredmeny" element={<ResultPage />} />
            <Route path="/start" element={<CalculatorPage />} />
            <Route path="/" element={<MarketingPage />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </div>
      <Footer />
    </div>
  );
};

export default App;
