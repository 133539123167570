import { useState } from "react";
import tr_logo from "../../assets/media/landing_page/tastyreform-logo-wbg.png";
import { FaFacebook, FaInstagramSquare } from "react-icons/fa";
import ContactForm from "../../components/ContactForm/ContactForm";
const Footer = () => {
  const [contactModal, setContactModal] = useState(false);

  const openContactModal = () => {
    setContactModal(true);
  };

  return (
    <>
      {contactModal ? (
        <ContactForm closeContactModal={() => setContactModal(false)} />
      ) : null}
      <footer className="app__footer">
        <div className="footer_wrapper">
          <div className="footer_col">
            <img
              src={tr_logo}
              title="TastyReform logó"
              alt="TastyReform logó"
            />{" "}
          </div>
          <div className="footer_col d-center">
            <div className=" footer_infos">
              <strong>Információk</strong>
              <span onClick={openContactModal}>Kapcsolat</span>
              <a href="/adatvedelem" target="_blank">
                <span>Adatvédelmi tájékoztató</span>
              </a>
              <a href="/aszf" target="_blank">
                <span>ÁSZF</span>
              </a>
            </div>
          </div>
          <div className="footer_col d-end">
            <div className="footer_icons">
              <a
                className="footer_icon"
                href="https://www.facebook.com/tastyreform.hungary"
                target="_blank"
              >
                <FaFacebook />
              </a>
              {/* <FaInstagramSquare className="footer_icon" /> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
